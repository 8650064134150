import styled from 'styled-components';
import { breakpointMobile, breakpointSmall } from '../../../constants';

export const Address = styled.div`
  max-width: 260px;
  text-overflow: ellipsis;
  overflow: ${({ cropped = false }) => (cropped ? 'hidden' : 'visible')};
  white-space: ${({ cropped = false }) => (cropped ? 'nowrap' : 'wrap')};

  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    max-width: 180px;
  }

  @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    max-width: 180px;
  }
  @media (max-width: ${breakpointMobile}px) {
    max-width: 50vw;
  }
  @media (max-width: ${breakpointSmall}px) {
    max-width: 120px;
  }
`;

export const AddressRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  max-width: 100%;
`;

export const CopyWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  margin-bottom: 4px;

  ${({ cropped }) =>
    cropped &&
    `
    margin-top: 2px;
  `}
`;
