import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const Copy = ({
  color = defaultIconColor,
  size = defaultIconSize,
  ...rest
}) => (
  <SVGIcon
    width={size}
    height={size}
    viewBox="0 0 24 24"
    title="Copy Icon"
    uid="cc-icon-copy"
    {...rest}>
    <rect x="9" y="9" width="13" height="13" rx="2" ry="2" fill={color} />
    <path
      d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
      stroke={color}
      strokeWidth="2"
      fill="none"
    />
  </SVGIcon>
);

Copy.propTypes = IconPropTypes;

export default Copy;
