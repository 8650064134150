import { getShopAddress, getShopContacts, getShopLocation } from '../../api';
import { textKeys } from '../../constants';

export const fixLinkText = url => {
  if (!url || !url.length) {
    return '';
  }
  const parts = url.split('://');
  if (parts[1]) {
    return parts[1].split('/')[0];
  }
  return url.split('/')[0];
};

export const fixLinkUrl = url => {
  if (!url || !url.length) {
    return '';
  }
  if (/^https?:\/\//.test(url)) {
    return url;
  }
  return `//${url}`;
};

export const getAddressText = (shop, displayLanguage) => {
  const address = getShopAddress(shop);
  if (!address) {
    return '';
  }
  const addressArrayChina = [
    address.Postcode,
    address.County,
    address.City,
    address.Street,
    address.Street2,
  ];
  const addressArray = [
    address.Street,
    address.Street2,
    [address.Postcode, address.City].join(' '),
    address.County,
    address.Country,
  ];
  const lang = displayLanguage.split('_')[0];
  const isChinese = lang === 'zh' || lang === 'zn';
  const addressCollection = isChinese ? addressArrayChina : addressArray;
  return isChinese
    ? addressCollection.join('')
    : addressCollection
        .filter(data => typeof data === 'string' && /[a-z0-9]/i.test(data))
        .map(data => data.trim())
        .join(', ');
};

export const fixPhoneUrl = phone => {
  return `tel:${phone.replace(/[^0-9+]/g, '')}`;
};

export const formatWhatsAppUrl = number => {
  return `https://wa.me/${number.replace(/\D/g, '')}`;
};

export const getContactData = ({
  shop,
  t,
  mapProvider = 'google',
  searchLocation = {},
  showWhatsApp,
}) => {
  const contacts = {};
  const contactInfo = getShopContacts(shop);
  const location = getShopLocation(shop);
  if (contactInfo) {
    const { Phone, Email, Website, WhatsApp } = contactInfo;
    if (Phone) {
      contacts.phone = {
        url: fixPhoneUrl(Phone),
        text: Phone.trim(),
      };
    }
    if (WhatsApp && showWhatsApp) {
      contacts.whatsapp = {
        url: formatWhatsAppUrl(WhatsApp),
        text: WhatsApp,
      };
    }
    if (Email) {
      contacts.email = {
        url: `mailto:${Email.trim().toLowerCase()}`,
        text: Email.trim(),
      };
    }
    if (Website) {
      contacts.website = {
        url: fixLinkUrl(Website),
        text: fixLinkText(Website),
      };
    }
  }
  if (location) {
    contacts.route = {
      url: createRouteUrl({ location, origin, mapProvider, searchLocation }),
      text: t(textKeys.LABEL_ROUTE),
    };
  }
  return contacts;
};

/**
 * @param location {{lat:number,lng:number}}
 * @param origin {{lat:number,lng:number}|null}
 */
export const createRouteUrl = ({
  location,
  origin = null,
  mapProvider = 'google',
  searchLocation,
}) => {
  const originLocation = searchLocation.lat
    ? {
        lat: searchLocation.lat,
        lng: searchLocation.lng,
      }
    : {
        lat: location.lat,
        lng: location.lng,
      };

  let routeUrl =
    mapProvider !== 'baidu'
      ? `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`
      : `https://api.map.baidu.com/direction?origin=latlng:${originLocation.lat},${originLocation.lng}|name:从这出发&destination=latlng:${location.lat},${location.lng}|name:到这去&mode=driving&region=none&output=html`;

  if (origin && origin.lat && origin.lng) {
    routeUrl += `&origin=${origin.lat},${origin.lng}`;
  }
  return routeUrl;
};

export const copyToClipboard = content => {
  if (navigator?.clipboard?.writeText) {
    return navigator.clipboard.writeText(content);
  } else {
    const textarea = document.createElement('textarea');
    textarea.value = content;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    return Promise.resolve();
  }
};
