import styled from 'styled-components';

export const AttrTile = styled.div`
  display: inline-block;
  width: auto;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  padding: 3px;
  font-size: 10px;
  border: 1px solid #bbb;
  color: #666666;
  border-radius: 3px;
`;

export const Wrapper = styled.div`
  padding: 10px 10px 0 0;
  ${({ numOfItemsPerRow }) => `
  > span {
    max-width: ${100 / numOfItemsPerRow}%;
    margin-right: 4px;
    &:not(:${numOfItemsPerRow}n + 1) {
      margin-left: 4px;
    }
 
  }
`}
`;
