import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDisplayLanguage, useLocale } from '../config';
import { translate, isRTLLanguage } from './utils';
// import defaultTranslations from './translations';

const defaultLocale = 'en-US';
// const defaultMessages = defaultTranslations[defaultLocale];

// --- The correct locale for Taiwan is zh_TW, but our translations are stored under zn_TW.
// --- If brands implement zh_TW, map it to the key we support (zn_TW).
const handleTaiwanLanguage = lang => {
  if (lang === 'zh_TW') {
    return 'zn_TW';
  }

  return lang;
};

const initState = ({ locale, translations }) => {
  const language = locale.replace('-', '_').split('_')[0];
  const mergedTranslations = translations;

  if (translations) {
    const messages = {
      ...mergedTranslations[language],
      ...mergedTranslations[locale],
    };
    return {
      locale,
      messages,
      rtl: isRTLLanguage(language),
      translations: mergedTranslations,
      translate: key => translate(key, messages),
    };
  }
};

export const I18nContext = createContext(initState({ locale: defaultLocale }));

export function I18nProvider({
  children,
  locale: customLocale,
  messages: customTranslations,
  globalTranslations,
  confTranslations,
}) {
  Object.keys(confTranslations).forEach(label => {
    Object.keys(confTranslations[label]).forEach((lang, i) => {
      globalTranslations[lang][label] = confTranslations[label][lang];
    });
  });

  const lang = useDisplayLanguage();
  const displayLanguage = handleTaiwanLanguage(lang);

  const { locale } = useLocale();
  const [state, setState] = useState(
    initState({
      locale: customLocale || displayLanguage,
      translations: globalTranslations,
    })
  );

  useEffect(() => {
    setState(
      initState({
        locale: customLocale || displayLanguage || locale,
        translations: globalTranslations,
      })
    );
  }, [customLocale, globalTranslations, displayLanguage, locale]);

  return <I18nContext.Provider value={state}>{children}</I18nContext.Provider>;
}

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string,
  messages: PropTypes.object,
};
