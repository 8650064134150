import React, { useState, useEffect, useRef } from 'react';

import { CheckboxField } from '../Checkbox';
import { CaretDownIcon, CaretUpIcon, CloseIcon, ArrowLeftIcon } from '../Icon';
import { buttonKinds } from '../Button';
import { textKeys, confKeys, widgetModes } from '../../constants';
import {
  useTranslation,
  useTrackingContext,
  useAvailableFiltersLocalized,
  useResultsDisplayMode,
  useNumOfFilteredResults,
  useSearchLocation,
  useActiveSearchFilters,
  useVisibleFilters,
  useSearchAction,
  useIsMobile,
  useSearchResults,
} from '../../hooks';
import { useConfigValue } from '../../contexts/config';
import { Spacer } from '../Layout';
import { searchActionTypes } from '../../contexts/search';
import { groupObjects } from '../../utils/filters';
import {
  DropdownWrapper,
  StyledSelect,
  DropdownButton,
  DropdownButtonText,
  MobileLabel,
  Item,
  CloseButton,
  ButtonRow,
  ActionButton,
  GoBack,
  GoBackText,
  IconButton,
  SelectWrapper,
  Badge,
} from './DropdownStyled';

const ccid = 'search-filter';

const getDropdownTop = (type, ref, isMultiple, target) => {
  const offset = 52;

  if (type === widgetModes.POPUP) {
    const dropdownTop = ref?.current?.getBoundingClientRect().top;
    return dropdownTop + 45;
  }
  // position of widget + button height + padding
  return !isMultiple
    ? document.querySelector('#cc-storefinder-root')?.getBoundingClientRect()
        .top + offset
    : document.querySelector(`#${target}`)?.getBoundingClientRect().top +
        offset;
};

export const Dropdown = ({
  filter,
  disabledFilters,
  onChange,
  getDisplayModeDetails,
  selectedFilters,
  setSelectedFilters,
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [left, setLeft] = useState(0);
  const type = useConfigValue(confKeys.WIDGET_MODE);
  const isMultiple = useConfigValue(confKeys.IS_MULTIPLE);
  const targetContainer = useConfigValue(confKeys.TARGET_CONTAINER);
  const [top, setTop] = useState(
    getDropdownTop(type, ref, isMultiple, targetContainer)
  );
  const [numOfSelectedFilters, setNumOfSelectedFilters] = useState(0);
  const label = filter[0].name;
  const trackingContext = useTrackingContext();
  const { track } = trackingContext;
  const t = useTranslation();
  const activeFilters = useActiveSearchFilters();
  const visibleFilters = useVisibleFilters();
  const availableFilters = useAvailableFiltersLocalized();
  const [displayMode] = useResultsDisplayMode();
  const numOfShops = useNumOfFilteredResults();
  const dispatch = useSearchAction();
  const isMobile = useIsMobile();
  const searchResults = useSearchResults();
  const getButtonText = () => {
    const {
      CTA_DISPLAY_RETAILER,
      CTA_DISPLAY_RETAILERS,
      FILTER_NO_RESULTS,
    } = textKeys;
    if (numOfShops === 0) return t(FILTER_NO_RESULTS);
    if (numOfShops === 1) return t(CTA_DISPLAY_RETAILER);
    return t(CTA_DISPLAY_RETAILERS).replace('xx', numOfShops);
  };
  const [mainBtnText, setMainBtnText] = useState(getButtonText());
  const [isMainBtnDisabled, setIsMainBtnDisabled] = useState(numOfShops === 0);

  const themeColor = useConfigValue(confKeys.COLOR_SCHEME);

  const searchLocation = useSearchLocation();

  useEffect(() => {
    let num = 0;
    const activeFiltersIds = activeFilters.map(({ id }) => id);
    activeFiltersIds.map(id => filter.some(value => value.id === id) && num++);
    setNumOfSelectedFilters(num);
  }, [activeFilters, selectedFilters, filter]);

  useEffect(() => {
    if (selectedFilters && selectedFilters.length) {
      const selected = [];

      selectedFilters.forEach(item => {
        filter.forEach(filterItem => {
          if (filterItem.id === item) {
            selected.push(filterItem.label);
          }
        });
      });

      setSelectedValues(selected);
    }
  }, [filter, selectedFilters]);

  useEffect(() => {
    if (!isOpen && activeFilters.length === 0) {
      setSelectedFilters([]);
    }
  }, [isOpen, activeFilters, setSelectedFilters]);

  useEffect(() => {
    setMainBtnText(getButtonText());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numOfShops, searchLocation, t, activeFilters]);

  useEffect(() => {
    setIsMainBtnDisabled(numOfShops === 0);
  }, [numOfShops, selectedFilters]);

  useEffect(() => {
    setTop(getDropdownTop(type, ref));
  }, [ref, type]);

  const trackFilter = (selected, filterAction, customPayload) => {
    const defaultPayload = {
      selected_filters: groupObjects(selected),
      available_filters: groupObjects(visibleFilters),
      filter_action: filterAction,
      displayed_tab_type: displayMode,
      displayed_tab_name: getDisplayModeDetails().label,
      displayed_tab_position: displayMode === 'list' ? 1 : 0,
    };
    let payload = { ...defaultPayload };
    if (customPayload) payload = { ...payload, ...customPayload };
    track('filter_click', payload);
  };

  const handleLeftPosition = () => {
    const newLeftPosition = Math.round(
      ref.current.getBoundingClientRect().left
    );
    const widgetWidth = isMultiple
      ? document.querySelector(`#${targetContainer}`)?.getBoundingClientRect()
          .width
      : document.querySelector('#cc-storefinder-root')?.getBoundingClientRect()
          .width;
    const spaceWithoutWidget = window.innerWidth - widgetWidth;
    const widgetStart = spaceWithoutWidget / 2;
    const dropdownWidth = 250;
    const maxLeft = widgetStart + widgetWidth - dropdownWidth;
    const newLeft = Math.min(newLeftPosition, maxLeft);
    setLeft(newLeft);
  };

  let timer = null;
  const handleTopPosition = () => {
    const wasOpen = isOpen;
    if (wasOpen) setIsOpen(false);
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setTop(getDropdownTop(type, ref));
      if (wasOpen) setIsOpen(true);
    }, 1000);
  };

  const handleToggle = () => {
    handleLeftPosition();
    if (!isOpen) {
      trackFilter(selectedFilters, 'dropdown', {
        selected_filter_dropdown: filter[0].groupId,
      });
    }
    setIsOpen(!isOpen);
  };

  const getIsDisabled = filterId => {
    return disabledFilters.indexOf(filterId) !== -1;
  };

  const handleHideDropdown = event => {
    if (event.key === 'Escape') {
      setTimeout(() => {
        setIsOpen(false);
      });
    }
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleFilter = event => {
    const checked = event.target.value;
    const filterItem = filter.find(item => item.id === checked);
    const selectedItems = selectedValues;
    const index = selectedItems.indexOf(filterItem.label);

    if (index === -1) {
      selectedItems.push(filterItem.label);
    } else {
      selectedItems.splice(index, 1);
    }

    setSelectedValues(selectedItems);
    handleChangeFilter(event);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    window.addEventListener('resize', handleLeftPosition);
    window.addEventListener('scroll', handleTopPosition);

    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
      window.removeEventListener('resize', handleLeftPosition);
      window.removeEventListener('scroll', handleTopPosition);
    };
  });

  const handleApplyFilters = () => {
    trackFilter(selectedFilters, 'apply');
    onChange(selectedFilters);
    setIsOpen(false);
  };

  const handleResetFilters = () => {
    onChange([]);
    trackFilter(selectedFilters, 'clear');
    setSelectedFilters([]);
    setIsOpen(false);
  };

  const handleChangeFilter = event => {
    const { value, checked } = event.target;
    const changedFilter = availableFilters.filter(({ id }) => id === value)[0];
    if (!changedFilter) {
      return;
    }
    const currentFilters = selectedFilters.slice();
    const currentFilterIds = selectedFilters.map(({ id }) => id);
    const index = currentFilterIds.indexOf(value);
    if (checked && index === -1) {
      trackFilter([changedFilter], 'select');
      currentFilters.push(changedFilter);
    } else if (!checked && index !== -1) {
      trackFilter([changedFilter], 'deselect');
      currentFilters.splice(index, 1);
    }
    setSelectedFilters(currentFilters);
  };

  useEffect(() => {
    dispatch({
      type: searchActionTypes.UPDATE_NUM_OF_FILTERED_RESULTS,
      filters: selectedFilters,
    });
  }, [activeFilters, selectedFilters, dispatch, searchLocation, searchResults]);

  return (
    <DropdownWrapper ref={ref}>
      <DropdownButton
        onClick={handleToggle}
        open={isOpen}
        themeColor={themeColor}>
        <DropdownButtonText title={selectedValues.toString()}>
          {label}
        </DropdownButtonText>
        {!isOpen ? <CaretDownIcon /> : <CaretUpIcon />}
        {numOfSelectedFilters > 0 && (
          <Badge themeColor={themeColor}>{numOfSelectedFilters}</Badge>
        )}
      </DropdownButton>

      {isOpen && (
        <SelectWrapper left={left} top={top}>
          <StyledSelect id="services" name="services">
            {isMobile && (
              <GoBack>
                <IconButton
                  onClick={handleToggle}
                  aria-label="Close Button"
                  title={t(textKeys.LABEL_CLOSE)}>
                  <ArrowLeftIcon />
                </IconButton>

                <GoBackText>{t(textKeys.FILTER_BACK_TO_RETAILERS)}</GoBackText>
              </GoBack>
            )}
            {!isMobile && (
              <CloseButton
                onClick={handleToggle}
                aria-label="Close Button"
                title={t(textKeys.LABEL_CLOSE)}>
                <CloseIcon />
              </CloseButton>
            )}

            {isMobile && <MobileLabel>{label}</MobileLabel>}
            {filter.map(({ id, label }) => {
              const disabled = getIsDisabled(id);
              return (
                <Item key={`filter-${id}`} data-ccid={`filter-list-item`}>
                  <CheckboxField
                    id={id}
                    label={label}
                    value={id}
                    onChange={handleFilter}
                    checked={
                      disabled
                        ? false
                        : selectedFilters.some(filter => filter.id === id)
                    }
                    disabled={disabled}
                  />
                </Item>
              );
            })}
            <ButtonRow>
              <ActionButton
                data-ccid={`${ccid}-apply-button`}
                onClick={handleApplyFilters}
                disabled={isMainBtnDisabled}>
                {mainBtnText}
              </ActionButton>
              <Spacer size={10} />
              <ActionButton
                data-ccid={`${ccid}-reset-button`}
                disabled={activeFilters.length === 0}
                onClick={handleResetFilters}
                kind={
                  isMainBtnDisabled
                    ? buttonKinds.PRIMARY
                    : buttonKinds.SECONDARY
                }>
                {t(textKeys.CTA_CLEAR_FILTER)}
              </ActionButton>
            </ButtonRow>
          </StyledSelect>
        </SelectWrapper>
      )}
    </DropdownWrapper>
  );
};
