import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Address, AddressRow, CopyWrapper } from './LocalStoreAddressStyled';
import { LocalStoreShape } from '../../../api';
import { Container } from '../../Layout';
import { getAddressText, copyToClipboard } from '../utils';
import { useDisplayLanguage } from '../../../contexts/config';
import ReactTooltip from 'react-tooltip';
import { CopyIcon, CheckIcon } from '../../Icon/ui';

const ccid = 'localstore-address';

export const LocalStoreAddress = ({ cropped, shop, ...otherProps }) => {
  const [copied, setCopied] = useState(false);

  const addressText = getAddressText(shop, useDisplayLanguage());

  const handleCopyAddress = useCallback(() => {
    copyToClipboard(addressText);
    setCopied(true);
    setTimeout(() => setCopied(false), 800);
  }, [addressText]);

  return cropped ? (
    <Container data-ccid={`${ccid}-container`} data-iscapture="true">
      <ReactTooltip id="store-address-tooltip" />
      <AddressRow>
        <Address
          data-tip={addressText}
          data-for="store-address-tooltip"
          data-ccid={ccid}
          {...otherProps}>
          {addressText}
        </Address>
        <CopyWrapper
          data-clickout-type="store_copy_address"
          cropped={cropped}
          onClick={handleCopyAddress}>
          {copied ? (
            <CheckIcon size={16} color="currentColor" />
          ) : (
            <CopyIcon size={16} color="currentColor" />
          )}
        </CopyWrapper>
      </AddressRow>
    </Container>
  ) : (
    <AddressRow>
      <Address data-ccid={ccid} {...otherProps}>
        {addressText}
      </Address>
      <CopyWrapper
        data-clickout-type="store_copy_address"
        cropped={cropped}
        onClick={handleCopyAddress}>
        {copied ? (
          <CheckIcon size={16} color="currentColor" />
        ) : (
          <CopyIcon size={16} color="currentColor" />
        )}
      </CopyWrapper>
    </AddressRow>
  );
};

LocalStoreAddress.propTypes = {
  cropped: PropTypes.bool,
  shop: LocalStoreShape.isRequired,
};

export default LocalStoreAddress;
