import React from 'react';
import { MapPropTypes } from '../types';
import mapStyles from './mapStyles';
import withMapsApi from './withMapsApi';
import {
  toLatLng,
  centerMap,
  fixCenterOffset,
  // fitBoundsWithPadding,
  getSearchMarkerIcon,
  getMarkerIcon,
  getHighlightedMarkerIcon,
  getActiveMarkerIcon,
  getMarkerMaxZIndex,
  MarkerClusterer,
} from './utils';

const getMapTheme = themeName => {
  switch (themeName) {
    case 'grey':
      return mapStyles.grey;
    case 'color':
    case 'simple':
    case 'default':
    default:
      return mapStyles.simple;
  }
};

const getMapPadding = (mapPadding = {}) => {
  return {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    ...mapPadding,
  };
};

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMapReady: false,
      mapInstance: null,
      mapMarkers: null,
      mapPadding: getMapPadding(props.mapPadding),
      searchMarker: null,
    };

    this.mapContainer = null;
  }

  componentDidMount() {
    const { mapContainer } = this;
    const { mapTheme } = this.props;
    this.createMap(mapContainer, mapTheme);
  }

  componentWillUnmount() {
    const { google } = window;
    const { mapInstance, mapMarkers } = this.state;
    this.clearMapViewportTimeout();
    mapMarkers &&
      mapMarkers.forEach(mapMarker => {
        google.maps.event.clearInstanceListeners(mapMarker.marker);
        mapMarker.marker.setMap(null);
      });
    mapInstance && google.maps.event.clearInstanceListeners(mapInstance);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isMapReady, mapInstance } = this.state;
    const {
      isMobile,
      mapPadding,
      mapTheme,
      markers,
      markerColor,
      activeMarkerColor,
      clusterMarkerColor,
      activeMarkerId,
      highlightedMarkerId,
    } = this.props;

    let markersDidChange = false;
    if (isMapReady) {
      if (markers !== prevProps.markers) {
        markersDidChange = true;
      }
      if (mapTheme !== prevProps.mapTheme) {
        mapInstance.setOptions({ styles: getMapTheme(mapTheme) });
      }
      if (
        markerColor !== prevProps.markerColor ||
        activeMarkerColor !== prevProps.activeMarkerColor ||
        clusterMarkerColor !== prevProps.clusterMarkerColor
      ) {
        markersDidChange = true;
      }
      if (markersDidChange) {
        this.updateMarkers();
      } else {
        if (activeMarkerId !== prevProps.activeMarkerId) {
          this.activateMarker(activeMarkerId);
        }
        if (highlightedMarkerId !== prevProps.highlightedMarkerId) {
          this.highlightMarker(highlightedMarkerId);
        }
      }
    }

    if (isMobile !== prevProps.isMobile) {
      this.setState(
        {
          mapPadding: getMapPadding(mapPadding),
        },
        this.resetMapBounds
      );
    }
  }

  setContainerRef = el => {
    this.mapContainer = el;
  };

  handleMapReady = () => {
    this.updateMarkers();
  };

  createMap = (container, mapTheme) => {
    const { google } = window;

    const styles = getMapTheme(mapTheme);
    const viewport = this.getMapViewport();
    const options = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      styles: styles,
      streetViewControl: false,
      disableDefaultUI: true,
      scrollwheel: true,
      draggable: true,
      ...viewport,
    };

    const mapInstance = new google.maps.Map(container, options);
    this.setState({
      mapInstance: mapInstance,
    });
    google.maps.event.addListenerOnce(mapInstance, 'idle', () => {
      this.setState(
        {
          isMapReady: true,
          markerClusterer: this.createMarkerClusterer(mapInstance),
        },
        this.handleMapReady
      );
    });
  };

  createMarkerClusterer = map => {
    const {
      clusterMarkerColor,
      clusterTextColor,
      clusterTextSize,
      clusterClassName,
    } = this.props;
    const defaultStyle = {
      height: 40,
      width: 40,
      textColor: clusterTextColor,
      backgroundColor: clusterMarkerColor,
      textSize: clusterTextSize,
      cssClassName: clusterClassName,
    };
    return new MarkerClusterer(map, [], {
      maxZoom: 14,
      gridSize: 40,
      styles: [
        {
          ...defaultStyle,
        },
        {
          ...defaultStyle,
          height: 50,
          width: 50,
        },
        {
          ...defaultStyle,
          height: 60,
          width: 60,
        },
      ],
    });
  };

  removeMapMarker = mapMarker => {
    if (mapMarker.marker) {
      const { google } = window;
      google.maps.event.clearInstanceListeners(mapMarker.marker);
      mapMarker.marker.setMap(null);
      mapMarker.marker = null;
    }
  };

  createMapMarker = data => {
    const { google } = window;
    const { mapInstance } = this.state;
    const {
      activeMarkerId,
      highlightedMarkerId,
      markerColor,
      activeMarkerColor,
    } = this.props;

    let markerIcon;
    let markerIsActive = false;
    let markerIsHighlighted = false;
    if (activeMarkerId && data.id === activeMarkerId) {
      markerIcon = getActiveMarkerIcon(activeMarkerColor);
      markerIsActive = true;
    } else if (highlightedMarkerId && data.id === highlightedMarkerId) {
      markerIcon = getHighlightedMarkerIcon(activeMarkerColor);
      markerIsHighlighted = true;
    } else {
      markerIcon = getMarkerIcon(markerColor);
    }

    const mapMarker = {
      id: data.id,
      active: markerIsActive,
      highlighted: markerIsHighlighted,
      marker: new google.maps.Marker({
        position: toLatLng(data.location),
        map: mapInstance,
        icon: markerIcon,
      }),
    };
    mapMarker.marker.addListener('click', () =>
      this.handleClickMapMarker(mapMarker)
    );
    return mapMarker;
  };

  updateMarkers = () => {
    const {
      mapInstance,
      searchMarker,
      mapMarkers,
      markerClusterer,
    } = this.state;
    const { google } = window;
    const { markers, searchLocation, activeMarkerId } = this.props;

    // search marker
    let currentSearchMarker = searchMarker;
    if (searchLocation) {
      switch (searchLocation.type) {
        case 'street_address':
        case 'route':
          const searchLatLng = toLatLng(searchLocation);
          if (currentSearchMarker) {
            currentSearchMarker.setPosition(searchLatLng);
          } else {
            currentSearchMarker = new google.maps.Marker({
              position: searchLatLng,
              map: mapInstance,
              icon: getSearchMarkerIcon(),
            });
          }
          break;
        default:
          if (currentSearchMarker) {
            currentSearchMarker.setMap(null);
            currentSearchMarker = undefined;
          }
          break;
      }
    }

    // remove existing mapMarkers
    mapMarkers &&
      mapMarkers.forEach(mapMarker => {
        this.removeMapMarker(mapMarker);
      });

    if (markerClusterer) {
      markerClusterer.clearMarkers();
    }

    // create new mapMarkers
    const currentMapMarkers = [];
    if (markers) {
      markers &&
        markers.forEach(marker => {
          currentMapMarkers.push(this.createMapMarker(marker));
        });
    }

    if (markerClusterer) {
      markerClusterer.addMarkers(
        currentMapMarkers.map(mapMarker => mapMarker.marker)
      );
    }

    this.setState(
      {
        mapMarkers: currentMapMarkers,
        searchMarker: currentSearchMarker,
      },
      () => {
        if (activeMarkerId) {
          this.updateMapViewport();
        } else {
          this.resetMapBounds();
        }
      }
    );
  };

  getMapViewport = () => {
    const { markers, searchLocation } = this.props;
    const { google } = window;

    const mapZoom = 13;
    let mapCenter = null;
    let mapBounds = null;

    if (searchLocation) {
      mapCenter = toLatLng(searchLocation);
    }

    if (markers && markers.length) {
      mapBounds = new google.maps.LatLngBounds();
      if (searchLocation) {
        mapBounds.extend(toLatLng(searchLocation));
      }
      markers.forEach(marker => {
        mapBounds.extend(toLatLng(marker.location));
      });
      mapCenter = mapBounds.getCenter();
    }

    return {
      zoom: mapZoom,
      center: mapCenter,
      bounds: mapBounds,
    };
  };

  resetMapBounds = () => {
    const { mapInstance } = this.state;
    const viewport = this.getMapViewport();
    if (viewport.bounds) {
      mapInstance.fitBounds(viewport.bounds);
      // const bounds = fitBoundsWithPadding(
      //   mapInstance,
      //   viewport.bounds,
      //   mapPadding
      // );
      // mapInstance.fitBounds(bounds);
    } else {
      centerMap(mapInstance, viewport);
    }
  };

  highlightMarker = markerId => {
    const { mapMarkers } = this.state;
    if (!mapMarkers) return;
    const { markerColor, activeMarkerColor } = this.props;
    const { google } = window;
    mapMarkers.forEach(mapMarker => {
      const highlight =
        !mapMarker.active && markerId && markerId === mapMarker.id;
      if (highlight) {
        if (!mapMarker.highlighted) {
          mapMarker.highlighted = true;
          mapMarker.zIndex = mapMarker.marker.getZIndex();
          mapMarker.marker.setZIndex(getMarkerMaxZIndex());
          mapMarker.marker.setIcon(getHighlightedMarkerIcon(activeMarkerColor));
          mapMarker.marker.setAnimation(google.maps.Animation.BOUNCE);
        }
      } else if (mapMarker.highlighted) {
        mapMarker.highlighted = false;
        if (!mapMarker.active) {
          mapMarker.marker.setZIndex(mapMarker.zIndex);
          mapMarker.marker.setIcon(getMarkerIcon(markerColor));
          mapMarker.marker.setAnimation(null);
        }
      }
    });
  };

  activateMarker = markerId => {
    const { mapMarkers } = this.state;
    if (!mapMarkers) return;
    const { markerColor, activeMarkerColor } = this.props;
    mapMarkers.forEach((mapMarker, index) => {
      const isActiveMarker = markerId && markerId === mapMarker.id;
      if (isActiveMarker) {
        mapMarker.active = true;
        mapMarker.zIndex = mapMarker.marker.getZIndex();
        mapMarker.marker.setIcon(getActiveMarkerIcon(activeMarkerColor));
        mapMarker.marker.setZIndex(getMarkerMaxZIndex());
        mapMarker.marker.setAnimation(null);
      } else if (mapMarker.active) {
        mapMarker.active = false;
        mapMarker.marker.setIcon(getMarkerIcon(markerColor));
        mapMarker.marker.setZIndex(mapMarker.zIndex);
        mapMarker.marker.setAnimation(null);
      }
    });
    this.invalidateMapViewport();
  };

  clearMapViewportTimeout = () => {
    if (this.mapViewportTimeout) {
      clearTimeout(this.mapViewportTimeout);
      this.mapViewportTimeout = null;
    }
  };

  invalidateMapViewport = () => {
    this.clearMapViewportTimeout();
    this.mapViewportTimeout = setTimeout(() => {
      this.mapViewportTimeout = null;
      this.updateMapViewport();
    }, 250);
  };

  updateMapViewport = () => {
    const { isMapReady, mapInstance, mapMarkers } = this.state;
    const { activeMarkerId } = this.props;
    if (!isMapReady) return;

    let activeMapMarker = null;
    if (activeMarkerId && mapMarkers) {
      mapMarkers.forEach(mapMarker => {
        if (mapMarker.id === activeMarkerId) {
          activeMapMarker = mapMarker;
        }
      });
    }
    if (activeMapMarker) {
      mapInstance.setZoom(16);
      mapInstance.panTo(
        fixCenterOffset(
          mapInstance,
          activeMapMarker.marker.getPosition(),
          this.getMapCenterOffset()
        )
      );
    } else {
      this.resetMapBounds();
    }
  };

  handleClickMapMarker = mapMarker => {
    const { onClickMarker } = this.props;
    onClickMarker(mapMarker.id);
  };

  getMapCenterOffset = () => {
    const { mapPadding } = this.state;
    const offset = {
      top: !isNaN(mapPadding.top) ? mapPadding.top : 0,
      bottom: !isNaN(mapPadding.bottom) ? mapPadding.bottom : 0,
      left: !isNaN(mapPadding.left) ? mapPadding.left : 0,
      right: !isNaN(mapPadding.right) ? mapPadding.right : 0,
    };
    return {
      x: 0.5 * (offset.left - offset.right),
      y: -0.5 * (offset.top - offset.bottom),
    };
  };

  render() {
    return (
      <div
        className={this.props.className}
        ref={this.setContainerRef}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
      />
    );
  }
}

Map.propTypes = MapPropTypes;

Map.defaultProps = {
  markerColor: '#000000',
  activeMarkerColor: '#000000',
  clusterMarkerColor: '#000000',
  clusterTextColor: '#ffffff',
  clusterTextSize: 14,
  onClickMarker: () => {},
  mapTheme: 'default',
};

export default withMapsApi(Map);
