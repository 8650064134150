/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useAvailableFiltersLocalized,
  useIsMobile,
  useActiveSearchFilters,
  useTrackingContext,
  useResultsDisplayMode,
  useDisplayModesLocalized,
  useSearchAction,
} from '../../hooks';
import { Dropdown } from '../Dropdown/Dropdown';
import { ArrowLeftIcon } from '../Icon';
import { breakpointMobile } from '../../constants';
import { searchActionTypes } from '../../contexts/search';
import {
  groupObjects,
  calculateScrollAmount,
  isVisible,
} from '../../utils/filters';
import { useIsRtlLanguage } from '../../hooks';

const getPadding = (theme, scrollPosition) => {
  if (scrollPosition !== 0) {
    return theme.rtl ? '0 16px 0 0' : '0 0 0 16px';
  }
};

const Inner = styled.div`
  min-width: 300px;
  max-width: 100%;
  padding: ${({ theme, scrollX }) => getPadding(theme, scrollX)};
  display: flex;
  overflow-y: visible;
  position: relative;
  @media (max-width: ${breakpointMobile}px) {
    margin: 10px 0;
    align-self: flex-start;
  }
`;

const getLeft = (right, theme) => {
  if (!right) {
    return theme.rtl ? '0' : '16px';
  }
};

const getRight = (right, theme) => {
  if (right) {
    return !theme.rtl ? '0' : '16px';
  }
};

const ScrollButton = styled.div`
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.8) 40%,
    rgba(255, 255, 255, 0.9) 60%,
    rgba(255, 255, 255, 1) 100%
  );
  cursor: pointer;
  position: absolute;
  left: ${({ right, theme }) => getLeft(right, theme)};
  right: ${({ right, theme }) => getRight(right, theme)};
  z-index: 11;
  padding-left: 4px;
  ${({ right }) =>
    right &&
    `
  transform: rotate(180deg);
  `}
  > svg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding-top: 8px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;

  overflow-x: scroll;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    background: transparent;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  &:first-child {
    ${({ theme }) =>
      !theme.rtl &&
      `
      margin-left: 16px;
    `}
  }
`;

const ccid = 'search-filter';

export const SearchFilter = ({ onChange }) => {
  const [groupedFilters, setGroupedFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const availableFilters = useAvailableFiltersLocalized();
  const ref = useRef(null);
  const dropdownRef = useRef(null);
  const isRtl = useIsRtlLanguage();

  const getVisibleFilters = useCallback(() => {
    const visible = [];
    if (dropdownRef?.current?.children) {
      const array = Array.from(dropdownRef.current.children);
      array.forEach(element => {
        if (isVisible(element, ref.current)) {
          const filter = groupedFilters.find(item => {
            const filterName = element.textContent.replace(
              'Caret Down Icon',
              ''
            );
            return filterName === item[0].name;
          });

          if (filter) visible.push(...filter);
        }
      });
    }
    return visible;
  }, [groupedFilters]);
  const isMobile = useIsMobile();
  const getShowLeftScroll = () => {
    if (isMobile) return false;
    if (isRtl) return !scrolEnd;
    return scrollX > 0;
  };
  const getShowRightScroll = () => {
    if (isMobile) return false;
    if (isRtl) {
      return scrollX < 0;
    }
    return !scrolEnd;
  };
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  const [scrollEndPosition, setScrollEndPosition] = useState(null);
  const { track } = useTrackingContext();
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const [displayMode] = useResultsDisplayMode();
  const displayModeOptions = useDisplayModesLocalized();
  const dispatch = useSearchAction();
  const filters = useActiveSearchFilters();
  const getDisplayModeDetails = useCallback(() => {
    const displayModeDetails = displayModeOptions
      .filter(mode => mode.id === displayMode)
      .map((mode, index) => {
        return {
          label: mode.label,
          position: index,
        };
      })[0];

    return displayModeDetails;
  }, [displayMode, displayModeOptions]);

  const trackVisibleFilter = useCallback(
    visibleFilters => {
      track('view_additional_filter_click', {
        selected_filters: groupObjects(filters),
        available_filters: groupObjects(visibleFilters),
        filter_action: 'view_additional_filter_click',
        displayed_tab_type: displayMode,
        displayed_tab_name: getDisplayModeDetails().label,
        displayed_tab_position: displayMode === 'list' ? 1 : 0,
      });
    },
    [displayMode, getDisplayModeDetails, filters, track]
  );

  useEffect(() => {
    scrollCheck();
  }, []);

  useEffect(() => {
    const newVisibleFilters = getVisibleFilters();
    dispatch({
      type: searchActionTypes.UPDATE_VISIBLE_FILTERS,
      visibleFilters: newVisibleFilters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedFilters]);

  useEffect(() => {
    // fire event only when smooth scrolling is finished
    if (scrollEndPosition === ref?.current?.scrollLeft) {
      const newVisibleFilters = getVisibleFilters();
      dispatch({
        type: searchActionTypes.UPDATE_VISIBLE_FILTERS,
        visibleFilters: newVisibleFilters,
      });
      if (!isMobile) trackVisibleFilter(newVisibleFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollEndPosition, ref?.current?.scrollLeft]);

  const handleScrollEnd = () => {
    const { scrollWidth, scrollLeft, offsetWidth } = ref?.current;
    if (isRtl) {
      const remaining = scrollWidth - offsetWidth;
      if (scrollLeft === 0) {
        return setscrolEnd(remaining <= 0);
      }
      const isEnd = -1 * (scrollLeft - 1) >= remaining;
      setscrolEnd(isEnd);
    } else {
      const isEnd = Math.floor(scrollWidth - scrollLeft) <= offsetWidth;
      setscrolEnd(isEnd);
    }
  };

  useEffect(() => {
    setShowLeftScroll(getShowLeftScroll());
    setShowRightScroll(getShowRightScroll());
  }, [scrolEnd, scrollX, isMobile]);

  useEffect(() => {
    handleScrollEnd();
  }, [ref.current?.scrollWidth]);

  const scrollCheck = () => {
    setscrollX(ref.current.scrollLeft);
    handleScrollEnd();
    if (isMobile) setScrollEndPosition(ref.current.scrollLeft);
  };

  useEffect(() => {
    if (availableFilters) {
      const obj = {};
      const grouped = [];

      availableFilters.forEach(item => {
        const key = item['groupId'];
        if (obj[key]) {
          obj[key].push(item);
        } else {
          obj[key] = [];
          obj[key].push(item);
        }
      });

      Object.keys(obj).forEach(key => {
        grouped.push(obj[key]);
      });

      setGroupedFilters(grouped);
    }
  }, [availableFilters]);

  const scroll = direction => {
    const amount = calculateScrollAmount(direction, ref);
    const finalAmouunt = amount + ref.current.scrollLeft;
    setScrollEndPosition(finalAmouunt);
    ref.current.scrollLeft += amount;
    setscrollX(scrollX + amount);
    handleScrollEnd();
  };

  return (
    <Inner data-ccid={`${ccid}-content`} scrollX={scrollX}>
      <InnerWrapper ref={ref} onScroll={scrollCheck}>
        {showLeftScroll && (
          <ScrollButton onClick={() => scroll('left')}>
            <ArrowLeftIcon />
          </ScrollButton>
        )}
        <DropdownWrapper ref={dropdownRef}>
          {groupedFilters
            .filter(filter => filter[0]?.name !== 'Contact Via Whatsapp')
            .map(group => {
              const label = group[0].name;
              return (
                <Dropdown
                  key={label}
                  filter={group}
                  disabledFilters={[]}
                  onChange={onChange}
                  getDisplayModeDetails={getDisplayModeDetails}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                />
              );
            })}
        </DropdownWrapper>
        {showRightScroll && (
          <ScrollButton right onClick={() => scroll('right')}>
            <ArrowLeftIcon />
          </ScrollButton>
        )}
      </InnerWrapper>
    </Inner>
  );
};

SearchFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SearchFilter;
