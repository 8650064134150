import React from 'react';
import {
  SVGIcon,
  IconPropTypes,
  defaultIconSize,
  defaultIconColor,
} from '../SVGIcon';

const Check = ({
  color = defaultIconColor,
  size = defaultIconSize,
  ...rest
}) => (
  <SVGIcon
    width={size}
    height={size}
    viewBox="0 0 24 24"
    title="Check Icon"
    uid="cc-icon-check"
    {...rest}>
    <polyline
      points="20 6 9 17 4 12"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);

Check.propTypes = IconPropTypes;

export default Check;
