import React from 'react';
import axios from 'axios';
import { MapsContext } from '../context';
import {
  getForwardGeocodingEndpoint,
  getReverseGeocodingEndpoint,
} from './config';

function parseAddress(place) {
  if (!place) return null;

  const data = { address: place.formatted_address };

  if (Array.isArray(place.address_components)) {
    place.address_components.forEach(({ types, short_name, long_name }) => {
      if (types.includes('route')) {
        data.street = short_name;
      } else if (
        types.some(type => ['locality', 'postal_town'].includes(type))
      ) {
        data.city = long_name;
      } else if (types.includes('country')) {
        data.country = long_name;
        data.countryShortName = short_name;
      } else if (types.includes('postal_code')) {
        data.zip = short_name;
      }
    });

    if (data.zip && data.city && data.country) {
      data.address = `${data.zip} ${data.city}, ${data.country}`;
    }
  }

  return data;
}

function clientGeocode(request) {
  return new Promise((resolve, reject) => {
    if (!window.google || !window.google.maps) {
      return reject('Google Maps JavaScript API is not loaded');
    }
    new window.google.maps.Geocoder().geocode(request, (results, status) => {
      resolve(results[0]);
    });
  });
}

export function useForwardGeocoding() {
  const { apiKey, country, language } = React.useContext(MapsContext);

  return React.useCallback(
    async queryText => {
      const url = getForwardGeocodingEndpoint({
        apiKey,
        country,
        language,
        address: queryText,
      });
      try {
        const { data } = await axios.get(url);
        if (data.status === 'OK' && data.results.length > 0) {
          return data.results[0];
        }
        throw new Error('Fallback to client-side geocoding');
      } catch (error) {
        console.warn(
          'Server geocoding failed, falling back to client-side:',
          error
        );
        return clientGeocode({ address: queryText });
      }
    },
    [apiKey, country, language]
  );
}

export function useReverseGeocoding() {
  const { apiKey, country, language } = React.useContext(MapsContext);

  return React.useCallback(
    async latLng => {
      const url = getReverseGeocodingEndpoint({
        apiKey,
        country,
        language,
        location: latLng,
      });
      try {
        const { data } = await axios.get(url);
        if (data.status === 'OK' && data.results.length > 0) {
          return parseAddress(data.results[0]);
        }
        throw new Error('Fallback to client-side reverse geocoding');
      } catch (error) {
        console.warn(
          'Server reverse geocoding failed, falling back to client-side:',
          error
        );
        return clientGeocode({ location: latLng }).then(parseAddress);
      }
    },
    [apiKey, country, language]
  );
}
